import styles from '../assets/css/skeletoncontent.module.css';

export const SkeletonContent = ({ style, className, wrapperClassName, children, loading }) => {

    return (
        <div style={style} className={[styles.container, loading ? styles.loading : null, className].join(' ').trim()}>
            <div className={[styles.contentWrapper, wrapperClassName].join(' ').trim()}>
                <div className={styles.content}>
                    {children}
                </div>
                <div className={styles.skeleton}/>
            </div>
        </div>
    )

}

export default SkeletonContent;