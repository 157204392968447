import { useEffect, useState } from 'react';
import styles from '../assets/css/burger.module.css';

export const Burger = (props) => {

    const [active, setActive] = useState(false);

    useEffect(() => {

        if (props.active !== active){

            setActive(props.active);

        }

    }, [props.active, active])

    return <div className={[styles.wrapper, props.className, active ? styles.active : null].join(' ').trim()} onClick={() => {
        (props.onToggle ? props.onToggle : () => {})(!active);
        setActive(!active);
    }}>
        <div className={styles.container}>
            <div></div>
        </div>
    </div>

}

export default Burger;