import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from '../assets/css/sidebar.module.css';
import Burger from './Burger';
import Logo from '../assets/svgs/Logo';
import { ProjectSelector } from './ProjectSelector';
import { Link } from 'react-router-dom';
import Template from '../assets/svgs/Template';
import List from '../assets/svgs/List';
import Personal from '../assets/svgs/Personal';
import Connection from '../assets/svgs/Connection';
import Warn from '../assets/svgs/Warn';
import { useStore } from '../assets/js/Store';
import SkeletonContent from './SkeletonContent';

export const Sidebar = () => {
    
    const project = window.location.pathname.split('/').length > 2 ? window.location.pathname.split('/')[1] : null;
    const location = useLocation();
    const [menuActive, setMenuActive] = useState(false);
    const [loading, setLoading] = useState(true);
    const selectedProject = useStore((state) => state.selectedProject);
    const projectId = project ? project : selectedProject;
    const projects = useStore(state => state.projects);

    useEffect(() => setMenuActive(false), [location]);
    useEffect(() => setLoading(projects === null || projects === undefined), [projects]);
    
    return (
        <div className={[styles.sidebar, menuActive ? styles.active : null].join(' ').trim()}>
            <header className={styles.header}>
                <Logo className={styles.logo}/>
                <div className={styles.title}><b>{"Xenfuma"}</b>{" Developers"}</div>
                <Burger className={styles.burger} active={menuActive} onToggle={setMenuActive} />
            </header>
            <nav className={styles.navigation}>
                <ProjectSelector className={styles['project-selector']}/>
                <ul className={styles.items}>
                    <li className={[styles.item, location.pathname.substring(1).split('/')[0].length === 0 ? styles.selected : null].join(' ').trim()}>
                        <Link to={"/"} draggable="false">
                            <List/>
                            <span>{"Projects"}</span>
                        </Link>
                    </li>
                    { loading || (selectedProject && projects && projects.filter(x => x.id === selectedProject).length > 0) ? <>
                        <li className={[styles.item, location.pathname.substring(1).split('/')[1] === 'overview' ? styles.selected : null].join(' ').trim()}>
                            { loading ? <>
                                <div className={styles.wrapper}>
                                    <SkeletonContent className={styles.icon} wrapperClassName={styles.infill} loading={true}/>
                                    <SkeletonContent loading={true}>
                                        <span>{"General Information"}</span>
                                    </SkeletonContent>
                                </div>
                            </> : <>
                                <Link to={`/${projectId}/overview`} draggable="false">
                                    <Template/>
                                    <span>{"General Information"}</span>
                                </Link>
                            </>}
                        </li> 
                        <li className={[styles.item, location.pathname.substring(1).split('/')[1] === 'members' ? styles.selected : null].join(' ').trim()}>
                            { loading ? <>
                                <div className={styles.wrapper}>
                                    <SkeletonContent className={styles.icon} wrapperClassName={styles.infill} loading={true}/>
                                    <SkeletonContent loading={true}>
                                        <span>{"Members"}</span>
                                    </SkeletonContent>
                                </div>
                            </> : <>
                                <Link to={`/${projectId}/members`} draggable="false">
                                    <Personal style={{strokeWidth: 5}}/>
                                    <span>{"Members"}</span>
                                </Link>
                            </>}
                        </li>
                        <li className={[styles.item, location.pathname.substring(1).split('/')[1] === 'oauth' ? styles.selected : null].join(' ').trim()}>
                            { loading ? <>
                                <div className={styles.wrapper}>
                                    <SkeletonContent className={styles.icon} wrapperClassName={styles.infill} loading={true}/>
                                    <SkeletonContent loading={true}>
                                        <span>{"OAuth"}</span>
                                    </SkeletonContent>
                                </div>
                            </> : <>
                                <Link to={`/${projectId}/oauth`} draggable="false">
                                    <Connection/>
                                    <span>{"OAuth"}</span>
                                </Link>
                            </>}
                        </li>
                        <li className={[styles.item, location.pathname.substring(1).split('/')[1] === 'danger-zone' ? styles.selected : null].join(' ').trim()}>
                            { loading ? <>
                                <div className={styles.wrapper}>
                                    <SkeletonContent className={styles.icon} wrapperClassName={styles.infill} loading={true}/>
                                    <SkeletonContent loading={true}>
                                        <span>{"Danger Zone"}</span>
                                    </SkeletonContent>
                                </div>
                            </> : <>
                                <Link to={`/${projectId}/danger-zone`} draggable="false">
                                    <Warn/>
                                    <span>{"Danger Zone"}</span>
                                </Link>
                            </>}
                        </li>
                    </> : null}
                </ul>
            </nav>
        </div>
    )
}

export default Sidebar;