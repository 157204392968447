import styles from '../assets/css/loader.module.css'

export const Loader = (props) => {

    return (
        <div style={{width: props.size, height: props.size}} className={[styles.loader, props.className ? props.className : ''].join(' ').trim()}>
            <svg viewBox="0 0 50 50">
                <circle cx="25" cy="25" r="20" fill="none" strokeWidth="5" stroke={props.color}></circle>
            </svg>
        </div>
    )

}

export default Loader;