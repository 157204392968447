import styles from '../assets/css/profile.module.css';

import { Loader } from './Loader';
import Logout from '../assets/svgs/Logout';
import Developer from '../assets/svgs/Developer';
import Personal from '../assets/svgs/Personal';
import Articles from '../assets/svgs/Articles';
import Plus from '../assets/svgs/Plus';

import { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useStore } from '../assets/js/Store';
import { useSession } from '../assets/js/Api';

import { PrimaryButton } from '../components/Buttons';

import Config from '../config.json';
import Inbox from '../assets/svgs/Inbox';

export const Profile = (props) => {

    const profileRef = useRef(null);
    const inboxRef = useRef(null);
    const navigate = useNavigate();
    const [profileExpanded, setProfileExpanded] = useState(false);
    const [inboxExpanded, setInboxExpanded] = useState(false);
    const [avatarLoaded, setAvatarLoaded] = useState(false);

    const authenticated = useStore((state) => state.authenticated);
    const session = useSession();

    useEffect(() => {
        const clickOut = (event) => {
            if (profileRef.current && !profileRef.current.contains(event.target)) {
                setProfileExpanded(false);
            }
        };
        document.addEventListener('click', clickOut, true);
        return () => {
            document.removeEventListener('click', clickOut, true);
        };
    }, []);

    useEffect(() => {
        const clickOut = (event) => {
            if (inboxRef.current && !inboxRef.current.contains(event.target)) {
                setInboxExpanded(false);
            }
        };
        document.addEventListener('click', clickOut, true);
        return () => {
            document.removeEventListener('click', clickOut, true);
        };
    }, []);

    return (
        <>
            { session !== null && session !== undefined ? <>
                <div className={styles['nav-header']}>
                    <div ref={profileRef} className={[styles.profile, profileExpanded ? styles.expanded : null, props.className].join(' ').trim()}>
                        <Loader className={styles.loader} color={'#000'}/>
                        <img onLoad={() => setAvatarLoaded(true)} alt={''} onClick={() => setProfileExpanded(!profileExpanded)} className={[avatarLoaded ? styles.loaded : null, styles.profile].join(' ').trim()} src={session ? `https://cdn.xenfuma.com/avatars/${session.accounts[session.current].avatar}.png` : null}/>
                        <div className={styles.options}>
                            <div className={styles.current}>
                                <div className={styles['profile-picture']} style={{backgroundImage: session ? `url(https://cdn.xenfuma.com/avatars/${session.accounts[session.current].avatar}.png)` : null}}></div>
                                <div className={styles.details}>
                                    <div className={styles.username}>{session ? session.accounts[session.current].username : ''}</div>
                                    <div className={styles.email}>{session ?  session.accounts[session.current].email : ''}</div>
                                </div>
                            </div>
                            <div className={styles.body}>
                                <div className={styles.actions}>
                                    {
                                        false ? <>
                                            <button className={styles.option} draggable="false" onClick={() => navigate('/editor')}>
                                                <div className={styles['icon-wrapper']}>
                                                    <Plus style={{height: 15, width: 'auto', stroke: '#000'}}/>
                                                </div>
                                                <span>{"New Article"}</span>
                                            </button>
                                            <button className={styles.option} draggable="false" onClick={() => window.location = 'https://dev.xenfuma.com/'}>
                                                <div className={styles['icon-wrapper']}>
                                                    <Articles style={{height: 20, width: 'auto'}}/>
                                                </div>
                                                <span>{"My Articles"}</span>
                                            </button>
                                            <div className={styles.divider}></div>
                                        </> : null
                                    }
                                    <a href={`${Config.ACCOUNTS_API_ENDPOINT}`}>
                                        <button className={styles.option} draggable="false" onClick={() => window.location = Config.API_ENDPOINT}>
                                            <div className={styles['icon-wrapper']}>
                                                <Personal style={{height: 18, width: 'auto', strokeWidth: 4}}/>
                                            </div>
                                            <span>{"Manage Account"}</span>
                                        </button>
                                    </a>
                                    <a href={`https://dev.xenfuma.com/`}>
                                        <button className={styles.option} draggable="false" onClick={() => window.location = 'https://dev.xenfuma.com/'}>
                                            <div className={styles['icon-wrapper']}>
                                                <Developer style={{height: 12, width: 'auto'}}/>
                                            </div>
                                            <span>{"Developer Console"}</span>
                                        </button>
                                    </a>
                                    <div className={styles.divider}></div>
                                    <button className={styles.option} href={"/login"} draggable="false" onClick={() => navigate('/login')}>
                                        <div className={styles['icon-wrapper']}>
                                            <Logout/>
                                        </div>
                                        <span>{"Log Out"}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div ref={inboxRef} className={[styles.inbox, inboxExpanded ? styles.expanded : null].join(' ').trim()}>
                        <div className={styles.trigger} onClick={() => setInboxExpanded(!inboxExpanded)}>
                            <Inbox/>
                        </div>
                        <div className={styles.items}>
                            { true ? <>
                                <div className={styles.header}>{'Notifications'}</div>
                                <div className={styles.body}>
                                    <div className={[styles.item, styles.invite].join(' ').trim()}>
                                        <div className={styles.avatar}>
                                            <img src={'https://cdn.xenfuma.com/avatars/RKb8RMmR2nFekEnx7Te4nILRNqFLXuYo.png'}/>
                                        </div>
                                        <div className={styles.details}>
                                            <div>{'Tubinex invited you to join a project.'}</div>
                                        </div>
                                    </div>
                                </div>
                            </> : <>
                                <div className={styles.body}>
                                    <div className={styles.empty}>
                                        <div className={styles.icon}>
                                            <Inbox/>
                                        </div>
                                        <div className={styles.message}>{'You don\'t have any unread notifications.'}</div>
                                    </div>
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
            </> : <>
                <div className={[styles.accountButtons, props.className].join(' ').trim()}>
                    <a href={`${Config.ACCOUNTS_API_ENDPOINT}/login?next=${window.location.href}`}>
                        <PrimaryButton className={styles.signIn} label={'Sign In'} isLoading={authenticated === false || (authenticated === true && session === null)}/> 
                    </a>
                </div>
            </>}
        </>
    )

}

export const Account = (props) => {

    return (
        <div className={styles.account}>
            <div className={styles['profile-picture']} style={{backgroundImage: `url(${props.src})`}}></div>
            <div className={styles.details}>
                <div className={styles.username}>{props.username}</div>
                <div className={styles.email}>{props.email}</div>
            </div>
        </div>
    )

}

export const ProfileOption = (props) => {

    return (
        <div onClick={() => {
            
            if (!props.loading){
                props.onClick();
            }

        }} className={styles.option}>
            <div className={styles.label}>{props.label}</div>
            {props.loading ? <Loader className={styles.loader}/> : <></>}
        </div>
    )

}