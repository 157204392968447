export default function Logo (props) {

    return (
        <svg className={props.className} style={props.style} fill={props.fill || '#000'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 294.000000 274.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,274.000000) scale(0.100000,-0.100000)" stroke="none">
                <path d="M2280 2720 c-18 -18 -33 -20 -134 -20 l-113 0 -279 -384 c-154 -212 -281 -385 -284 -385 -3 0 -131 173 -285 384 l-280 384 -428 0 c-404 1 -427 0 -418 -16 5 -10 163 -229 350 -488 280 -386 341 -476 341 -502 0 -63 68 -95 110 -53 39 39 11 110 -43 110 -18 1 -85 88 -342 447 -176 245 -322 450 -323 455 -2 4 155 7 355 6 l358 -3 295 -392 c162 -216 300 -397 307 -404 8 -8 85 87 312 390 l301 401 90 0 c77 0 93 -3 110 -20 11 -11 31 -20 45 -20 14 0 34 9 45 20 11 11 20 31 20 45 0 14 -9 34 -20 45 -11 11 -31 20 -45 20 -14 0 -34 -9 -45 -20z"/>
                <path d="M2600 2720 c-11 -11 -20 -31 -20 -45 0 -56 70 -85 111 -44 12 12 39 22 71 26 l50 6 -75 -104 c-298 -410 -332 -453 -362 -461 -55 -14 -73 -70 -35 -108 45 -45 117 -7 107 57 -6 33 7 52 224 341 l230 307 -96 3 c-79 2 -99 6 -115 22 -26 26 -65 25 -90 0z"/>
                <path d="M620 2500 c-56 -56 21 -143 84 -95 11 8 32 15 48 15 25 0 62 -45 373 -460 190 -253 347 -460 350 -460 3 0 57 70 121 155 94 126 120 155 140 155 55 0 83 71 44 110 -42 42 -110 10 -110 -51 0 -22 -24 -64 -92 -159 -51 -71 -97 -128 -102 -126 -6 1 -159 204 -341 452 l-330 449 -39 3 c-22 2 -49 10 -60 18 -29 20 -63 17 -86 -6z"/>
                <path d="M2127 2458 c-19 -24 -74 -96 -122 -160 -57 -78 -94 -118 -106 -118 -27 0 -59 -36 -59 -65 0 -14 9 -34 20 -45 43 -43 116 -8 107 53 -3 26 10 50 94 167 54 75 104 142 112 149 10 8 49 11 124 9 l109 -3 -318 -430 c-246 -332 -325 -432 -346 -437 -52 -14 -69 -71 -32 -108 44 -44 116 -8 107 53 -4 29 27 75 321 472 179 242 336 455 350 473 l24 32 -175 0 -176 0 -34 -42z"/>
                <path d="M710 2160 c-11 -11 -20 -31 -20 -45 0 -29 32 -65 58 -65 16 0 138 -158 518 -665 l23 -31 -71 -102 c-62 -88 -76 -102 -100 -102 -58 0 -89 -69 -48 -110 42 -42 114 -8 108 49 -2 22 16 53 84 143 l86 115 -268 364 c-243 330 -268 366 -263 395 10 61 -63 98 -107 54z"/>
                <path d="M2150 1820 c-11 -11 -20 -33 -20 -51 0 -25 -29 -66 -150 -215 -82 -101 -150 -189 -150 -194 0 -6 83 -115 185 -244 106 -135 185 -243 185 -255 0 -29 35 -61 65 -61 32 0 65 33 65 65 0 33 -33 65 -69 65 -25 0 -49 25 -196 206 -134 165 -166 209 -159 223 5 10 68 93 139 184 98 125 136 166 153 167 30 0 62 34 62 65 0 14 -9 34 -20 45 -11 11 -31 20 -45 20 -14 0 -34 -9 -45 -20z"/>
                <path d="M930 1510 c-39 -39 -19 -97 39 -109 19 -3 40 -16 47 -27 13 -19 6 -32 -96 -167 -77 -103 -116 -147 -130 -147 -28 0 -60 -35 -60 -65 0 -14 9 -34 20 -45 44 -44 116 -7 107 53 -4 28 10 51 120 195 l124 163 -30 35 c-20 22 -31 46 -31 64 0 37 -31 70 -65 70 -14 0 -34 -9 -45 -20z"/>
                <path d="M1255 898 c-208 -245 -245 -283 -275 -289 -59 -12 -80 -69 -40 -109 44 -44 116 -7 107 53 -4 30 13 52 215 287 121 140 223 255 228 255 9 0 19 -12 298 -355 167 -207 212 -268 212 -291 0 -59 69 -90 110 -49 42 42 8 114 -50 108 -26 -2 -44 17 -247 272 -120 152 -238 303 -263 337 -25 34 -47 63 -50 63 -3 0 -113 -127 -245 -282z"/>
                <path d="M1780 1130 c-39 -39 -19 -96 37 -108 27 -6 72 -61 327 -397 l295 -390 -65 -3 c-60 -3 -68 -1 -89 23 -14 14 -35 25 -50 25 -32 0 -65 -33 -65 -65 0 -56 70 -85 110 -45 18 18 33 20 130 20 61 0 110 3 110 8 0 4 -143 196 -319 427 -287 379 -318 424 -314 452 9 61 -64 96 -107 53z"/>
                <path d="M880 860 c-11 -11 -20 -33 -20 -49 0 -22 -50 -94 -210 -306 -115 -153 -210 -281 -210 -286 0 -5 69 -9 153 -9 151 0 154 0 177 25 13 14 34 25 47 25 30 0 63 34 63 65 0 14 -9 34 -20 45 -44 44 -117 7 -107 -55 3 -18 0 -38 -6 -44 -15 -15 -220 -15 -215 -1 2 6 84 116 183 245 157 203 184 234 208 235 34 0 67 32 67 65 0 14 -9 34 -20 45 -11 11 -31 20 -45 20 -14 0 -34 -9 -45 -20z"/>
                <path d="M1150 459 l-315 -409 -358 0 c-225 0 -357 4 -355 10 2 5 107 149 233 319 182 247 235 311 252 311 30 0 63 34 63 65 0 14 -9 34 -20 45 -44 44 -116 8 -107 -53 4 -29 -22 -66 -264 -386 -148 -195 -267 -357 -264 -359 2 -2 195 -1 429 3 l425 7 298 389 c164 214 302 388 308 386 5 -2 13 -23 16 -47 9 -67 66 -93 109 -50 39 39 11 110 -44 110 -17 0 -34 12 -52 35 -14 19 -29 35 -33 34 -3 0 -148 -184 -321 -410z"/>
                <path d="M2450 600 c-11 -11 -20 -31 -20 -45 0 -29 32 -65 59 -65 13 0 70 -69 180 -215 l161 -215 -362 0 -363 1 -150 195 c-134 174 -150 199 -147 229 6 63 -64 99 -108 55 -11 -11 -20 -31 -20 -45 0 -26 31 -65 53 -65 6 -1 86 -95 177 -210 l165 -210 428 0 c235 0 427 2 427 6 0 3 -84 116 -187 251 -163 215 -187 251 -185 280 5 62 -65 96 -108 53z"/>
            </g>
        </svg>
    )
}