import baseStyles from '../assets/css/views/dashboard.module.css';
import styles from '../assets/css/views/members.module.css';
import headers from '../assets/css/headers.module.css';

import Search from '../assets/svgs/Search';
import Cross from '../assets/svgs/Cross';
import Loader from '../components/Loader';
import { startTransition, useEffect, useState } from 'react';
import { PrimaryButton } from '../components/Buttons';
import { addMember, removeMember, useCurrentProject } from '../assets/js/Api';
import SkeletonContent from '../components/SkeletonContent';
import { formatDate } from '../assets/js/Utils';
import { useParams } from 'react-router-dom';
import { useStore } from '../assets/js/Store';
import RemoveMember from './dialogs/RemoveMember';
import AddMember from './dialogs/AddMember';

export const Members = () => {

    const [searching, setSearching] = useState(null);
    const currentProject = useCurrentProject();
    const setDialog = useStore((state) => state.setDialog);
    const sessionId = useStore((state) => state.sessionId);
    const current = useStore((state) => state.current);
    const projects = useStore((state) => state.projects);
    const setProjects = useStore((state) => state.setProjects);

    const { project } = useParams();
    const setSelectedProject = useStore((state) => state.setSelectedProject);

    const [stasisProject, setStasisProject] = useState(null);
    const [memberSearchQuery, setMemberSearchQuery] = useState('');
    const [memberSearchResult, setMemberSearchResult] = useState([]);

    useEffect(() => {
        if (!stasisProject) return;
        setMemberSearchResult(([{
            created_on: stasisProject.created_on,
            id: stasisProject.creator.id,
            role: 1,
            user: {
                avatar: stasisProject.creator.avatar,
                id: stasisProject.creator.id,
                username: stasisProject.creator.username
            }
        },...stasisProject.members ? stasisProject.members : []]).filter((m) => {
            return m.user.username.toLowerCase().includes(memberSearchQuery.toLowerCase());
        }))
}, [stasisProject, memberSearchQuery]);

    useEffect(() => {
        if (!project) return;
        setSelectedProject(project);
    }, [project, setSelectedProject]);

    useEffect(() => {
        if (currentProject === null || stasisProject !== null) return;
        setStasisProject(currentProject);
    }, [stasisProject, setStasisProject, currentProject]);

    return (
        <div className={baseStyles.content}>
            <h1 className={[headers['header-1'], headers['no-bottom-margin']].join(' ').trim()}>{'Members'}</h1>
            <p>{'These are all the people who have access to this project'}</p>
            <div className={styles.actions}>
                <div className={[styles.search, searching ? styles.loading : null].join(' ').trim()}>
                    <div className={styles.icon}>
                        <Search/>
                    </div>
                    <input className={styles.input} placeholder={'Search...'} onInput={e => setMemberSearchQuery(e.target.value)}/>
                    <Loader className={styles.loader}/>
                </div>
                <PrimaryButton label={'Invite'} className={styles.invite} onClick={() => {
                    setDialog(<AddMember onInvite={(identifier, setLoading, setError) => {
                        setLoading(true);
                        addMember(sessionId, current, identifier, stasisProject.id).then(res => {
                            setLoading(false);
                            if (res.status === 200) {
                                setDialog(null);
                                setStasisProject({...stasisProject, members: res.data});
                                const projectIndex = projects.findIndex(p => p.id === stasisProject.id);
                                if (projectIndex !== -1) {
                                    const updatedProjects = [...projects];
                                    updatedProjects[projectIndex].members = res.data;
                                    setProjects(updatedProjects);
                                }
                            } else 
                                setError(res.message.trim());
                        });
                    }}/>)
                }}/>
            </div>
            { memberSearchQuery.length === 0 || memberSearchResult.length > 0 ? <>
                <div className={styles.wrapper}>
                    <div className={styles.labels}>
                        <div className={styles.label}>{'Name'}</div>
                        <div className={styles.label}>{'Role'}</div>
                        <div className={styles.label}>{'Added On'}</div>
                    </div>
                    <div className={styles.members}>
                        { memberSearchQuery.length > 0 ? <>
                            { memberSearchResult.map((member, i) => (
                                <Member 
                                    key={member.user.id} 
                                    id={member.user.id} 
                                    username={member.user.username} 
                                    role={member.role === 1 ? 'Owner' : member.role === 2 ? 'Member' : member.role === 3 ? 'Invited' : 'Unknown'} 
                                    date={formatDate(new Date(member.created_on))} avatar={member.user.avatar}
                                    removable={member.user.id !== stasisProject.creator.id}
                                    onRemove={() => {
                                        setDialog(<RemoveMember member={member} onRemove={setLoading => {
                                            setLoading(true);
                                            removeMember(sessionId, current, member, stasisProject.id).then(res => {
                                                setLoading(false);
                                                if (res.status === 200) {
                                                    setDialog(null);
                                                    setStasisProject({...stasisProject, members: res.data});
                                                    const projectIndex = projects.findIndex(p => p.id === stasisProject.id);
                                                    if (projectIndex !== -1) {
                                                        const updatedProjects = [...projects];
                                                        updatedProjects[projectIndex].members = res.data;
                                                        setProjects(updatedProjects);
                                                    }
                                                } else {
                                                    setDialog(null);
                                                }
                                            });
                                        }}/>);
                                    }}
                                />
                            )) }
                        </> : <>
                            { stasisProject ? <>
                                <Member id={stasisProject.creator.id} username={stasisProject.creator.username} role={'Owner'} date={formatDate(new Date(stasisProject.created_on))} avatar={stasisProject.creator.avatar} removable={false}/>
                                { (stasisProject.members ? stasisProject.members : []).map((member, i) => (
                                    <Member 
                                        key={member.user.id} 
                                        id={member.user.id} 
                                        username={member.user.username} 
                                        role={member.role === 1 ? 'Owner' : member.role === 2 ? 'Member' : member.role === 3 ? 'Invited' : 'Unknown'} 
                                        date={formatDate(new Date(member.created_on))} avatar={member.user.avatar}
                                        onRemove={() => {
                                            setDialog(<RemoveMember member={member} onRemove={setLoading => {
                                                setLoading(true);
                                                removeMember(sessionId, current, member, stasisProject.id).then(res => {
                                                    setLoading(false);
                                                    if (res.status === 200) {
                                                        setDialog(null);
                                                        setStasisProject({...stasisProject, members: res.data});
                                                        const projectIndex = projects.findIndex(p => p.id === stasisProject.id);
                                                        if (projectIndex !== -1) {
                                                            const updatedProjects = [...projects];
                                                            updatedProjects[projectIndex].members = res.data;
                                                            setProjects(updatedProjects);
                                                        }
                                                    } else {
                                                        setDialog(null);
                                                    }
                                                });
                                            }}/>);
                                        }}
                                    />
                                )) }
                            </> : <>
                                <Member loading={true} removable={false}/>
                                <Member loading={true} removable={false}/>
                                <Member loading={true} removable={false}/>
                                <Member loading={true} removable={false}/>
                                <Member loading={true} removable={false}/>
                                <Member loading={true} removable={false}/>
                            </>}
                        </>}
                    </div>
                </div>
            </> : null}
        </div>
    )
}

const Member = ({ id, avatar, username, role, date, onRemove, removable=true, loading=false }) => {
    const [loaded, setLoaded] = useState(false);
    return (
        <div className={styles.member} data-user-id={id}>
            { loading ? (
                <div className={[styles.avatar, loaded ? styles.loaded : null].join(' ').trim()}>
                    <SkeletonContent className={styles.skeleton} wrapperClassName={styles.wrapper} loading={true}/>
                </div>
            ) : (
                <div className={[styles.avatar, loaded ? styles.loaded : null].join(' ').trim()}>
                    <img alt={''} src={`https://cdn.xenfuma.com/avatars/${avatar}.png`} onLoad={() => setLoaded(true)}/>
                    <Loader className={styles.loader}/>
                </div>
            )}
            {
                loading ? (
                    <SkeletonContent className={styles.name} loading={true} children={'&nbsp;'}/>
                ) : (
                    <div className={styles.name}>{username}</div>
                )
            }
            {
                loading ? (
                    <SkeletonContent className={styles.role} loading={true} children={'&nbsp;'}/>
                ) : (
                    <div className={styles.role}>{role}</div>
                )
            }
            {
                loading ? (
                    <SkeletonContent className={styles.date} loading={true} children={'&nbsp;'}/>
                ) : (
                    <div className={styles.date}>{date}</div>
                )
            }
            <div className={styles.remove} {...(!removable ? {style: {opacity: 0}} : {})} onClick={onRemove}>
                <Cross/>
            </div>
        </div>
    )
}

export default Members;