import baseStyles from '../assets/css/views/dashboard.module.css';
import styles from '../assets/css/views/overview.module.css';
import headers from '../assets/css/headers.module.css';
import { useParams } from 'react-router-dom';
import SkeletonContent from '../components/SkeletonContent';
import { useEffect, useRef, useState } from 'react';
import Input from '../components/Input';
import TextArea from '../components/TextArea';
import Icon from './dialogs/Icon';
import { useStore } from '../assets/js/Store';
import ProjectIcon from '../assets/svgs/ProjectIcon';
import { setIcon, updateProjectDetails, useCurrentProject } from '../assets/js/Api';
import { PrimaryButton, SecondaryButton } from '../components/Buttons';

export const Overview = () => {

    const { project } = useParams();

    const iconRef = useRef(null);
    const projects = useStore((state) => state.projects);
    const setProjects = useStore((state) => state.setProjects);
    const currentProject = useCurrentProject();
    const setDialog = useStore((state) => state.setDialog);
    const setSelectedProject = useStore((state) => state.setSelectedProject);
    const [loading, setLoading] = useState(false);
    const [changed, setChanged] = useState(false);
    const [saving, setSaving] = useState(false);
    const [stasisProject, setStasisProject] = useState(null);
    const [state, setState] = useState(Math.random());
    const [errors, setErrors] = useState({
        name: null,
        description: null,
        website_uri: null,
        privacy_policy_uri: null,
        terms_of_service_uri: null
    })

    const sessionId = useStore((state) => state.sessionId);
    const current = useStore((state) => state.current);

    const [changes, setChanges] = useState({
        name: null,
        description: null,
        website_uri: null,
        privacy_policy_uri: null,
        terms_of_service_uri: null
    });

    useEffect(() => {
        if (!project) return;
        setSelectedProject(project);
    }, [project, setSelectedProject]);

    useEffect(() => {
        if (currentProject === null || stasisProject !== null) return;
        setStasisProject(currentProject);
        setChanges({
            name: null,
            description: null,
            website_uri: null,
            privacy_policy_uri: null,
            terms_of_service_uri: null
        });
    }, [setStasisProject, currentProject, stasisProject]);

    useEffect(() => setLoading(stasisProject === null || stasisProject === undefined), [stasisProject]);

    return (
        <div className={baseStyles.content}>
            <h1 className={[headers['header-1'], headers['no-bottom-margin']].join(' ').trim()}>{'General Information'}</h1>
            <p>{'This is the general information about this project, which will be used to represent it to users.'}</p>
            <section className={baseStyles.section}>  
                <div className={styles.heading}>
                    <div className={styles['icon-container']}>
                        <SkeletonContent loading={loading} className={styles.icon}>
                            { stasisProject && stasisProject.icon ? (
                                <img className={styles.icon} alt="App Icon" src={`https://cdn.xenfuma.com/icons/dev-projects/${stasisProject.icon}.png`} onClick={() => iconRef.current.click()}/>
                            ) : (
                                <div className={styles.icon} onClick={() => iconRef.current.click()}>
                                    <ProjectIcon/>
                                </div>
                            )}
                        </SkeletonContent>
                    </div>
                    <div className={styles.details}>
                        <div className={styles.item}>
                            <div className={styles.label}>{'Project Name'}</div>
                            <SkeletonContent loading={loading} className={styles.skeleton} wrapperClassName={styles.content}>
                                <Input className={styles.input} onFocus={() => setErrors({...errors, name: false})} error={errors.name} state={state} disabled={saving} value={stasisProject ? stasisProject.name : null} placeholder={'Unnamed Project'} onChange={v => {
                                    setChanged(true);
                                    setChanges({...changes, name: v});
                                }}/>
                            </SkeletonContent>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.label}>{'Description'}</div>
                            <SkeletonContent loading={loading} className={styles.skeleton} wrapperClassName={styles.content}>
                                <TextArea className={styles.input} onFocus={() => setErrors({...errors, description: false})} error={errors.description} state={state} disabled={saving} value={stasisProject ? stasisProject.description : null} onChange={v => {
                                    setChanged(true);
                                    setChanges({...changes, description: v});
                                }}/>
                            </SkeletonContent>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.label}>{'Website URL'}</div>
                            <SkeletonContent loading={loading} className={styles.skeleton} wrapperClassName={styles.content}>
                                <Input className={styles.input} onFocus={() => setErrors({...errors, website_uri: false})} error={errors.website_uri} state={state} disabled={saving} placeholder={'https://example.com'} value={stasisProject ? stasisProject.uri : null} onChange={v => {
                                    setChanged(true);
                                    setChanges({...changes, website_uri: v});
                                }}/>
                            </SkeletonContent>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.label}>{'Privacy Policy URL'}</div>
                            <SkeletonContent loading={loading} className={styles.skeleton} wrapperClassName={styles.content}>
                                <Input className={styles.input} onFocus={() => setErrors({...errors, privacy_policy_uri: false})} error={errors.privacy_policy_uri} state={state} disabled={saving} placeholder={'https://example.com/privacy-policy'} value={stasisProject ? stasisProject.privacy_policy_uri : null} onChange={v => {
                                    setChanged(true);
                                    setChanges({...changes, privacy_policy_uri: v});
                                }}/>
                            </SkeletonContent>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.label}>{'Terms of Service URL'}</div>
                            <SkeletonContent loading={loading} className={styles.skeleton} wrapperClassName={styles.content}>
                                <Input className={styles.input} onFocus={() => setErrors({...errors, terms_of_service_uri: false})} error={errors.terms_of_service_uri} state={state} disabled={saving} placeholder={'https://example.com/terms-of-service'} value={stasisProject ?  stasisProject.terms_of_service_uri : null} onChange={v => {
                                    setChanged(true);
                                    setChanges({...changes, terms_of_service_uri: v});
                                }}/>
                            </SkeletonContent>
                        </div>
                        { changed ? (
                            <div className={styles['button-tray']}>
                                <PrimaryButton label={'Save'} isLoading={saving} onClick={() => {
                                    setSaving(true);
                                    updateProjectDetails(sessionId, current, stasisProject, changes).then(res => {
                                        setErrors({});
                                        if (res.status === 200) {
                                            setStasisProject({...stasisProject, client: {...stasisProject.client, redirects: res.data}});
                                            const projectIndex = projects.findIndex(p => p.id === stasisProject.id);
                                            if (projectIndex !== -1) {
                                                const updatedProjects = [...projects];
                                                updatedProjects[projectIndex] = res.data;
                                                setProjects(updatedProjects);
                                                setSaving(false);
                                            }
                                        } else {
                                            setSaving(false);
                                            const copy = {};
                                            Object.keys(res.errors).forEach(k => copy[k] = true);
                                            setErrors(copy);
                                        }
                                    }).catch(err => {
                                        setSaving(false);
                                        const copy = {};
                                        Object.keys(err).forEach(k => {
                                            copy[k] = true;
                                        });
                                        setErrors(copy);
                                    });
                                }}/>
                                <SecondaryButton label={'Reset'} disabled={saving} onClick={() => {
                                    setChanged(false);
                                    setState(Math.random());
                                }}/>
                            </div>
                        ) : null}
                    </div>
                </div>
            </section>
            <input ref={iconRef} type={'file'} accept=".png,.gif,.jpeg,.jpg" style={{display: 'none'}} id={'avatar-input'} onChange={(e) => {
                const file = e.target.files[0];
                setDialog(<Icon image={file} onSubmit={icon => {
                    setIcon(sessionId, current, stasisProject.id, icon).then(res => {
                        setStasisProject({...stasisProject, icon: res.data.icon});
                        const projectIndex = projects.findIndex(p => p.id === stasisProject.id);
                        if (projectIndex !== -1) {
                            const updatedProjects = [...projects];
                            updatedProjects[projectIndex] = {...updatedProjects[projectIndex], icon: res.data.icon};
                            setProjects(updatedProjects);
                        }
                    });
                }}/>);
            }}/>
        </div>
    )
}

export default Overview;