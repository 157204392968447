import baseStyles from '../assets/css/views/dashboard.module.css';
import styles from '../assets/css/views/oauth.module.css';
import headers from '../assets/css/headers.module.css';
import SkeletonContent from '../components/SkeletonContent';
import { PrimaryButton } from '../components/Buttons';
import Loader from '../components/Loader';
import { useEffect, useRef, useState } from 'react';
import Cross from '../assets/svgs/Cross';
import { addRedirectUri, removeRedirectUri, resetSecret, useCurrentProject } from '../assets/js/Api';
import { useParams } from 'react-router-dom';
import { useStore } from '../assets/js/Store';
import { validateURL } from '../assets/js/Utils';

export const OAuth = () => {

    const { project } = useParams();
    const currentProject = useCurrentProject();
    const setSelectedProject = useStore((state) => state.setSelectedProject);
    const projects = useStore((state) => state.projects);
    const setProjects = useStore((state) => state.setProjects);

    const [loading, setLoading] = useState(true);
    const [secret, setSecret] = useState(null);
    const [resettingSecret, setResettingSecret] = useState(false);
    
    const [error, setError] = useState(null);
    const [copied, setCopied] = useState(false);
    const [copiedTimeout, setCopiedTimeout] = useState(null);
    const [secretCopied, setSecretCopied] = useState(false);
    const [secretCopiedTimeout, setSecretCopiedTimeout] = useState(null);
    const [stasisProject, setStasisProject] = useState(null);
    const [redirectUri, setRedirectUri] = useState('');
    const [settingRedirectUri, setSettingRedirectUri] = useState(false);
    const redirectRef = useRef(null);

    const sessionId = useStore((state) => state.sessionId);
    const current = useStore((state) => state.current);

    useEffect(() => {
        if (!project) return;
        setSelectedProject(project);
    }, [project, setSelectedProject]);

    useEffect(() => {
        if (currentProject === null || stasisProject !== null) return;
        setStasisProject(currentProject);
    }, [setStasisProject, currentProject, stasisProject]);

    useEffect(() => setLoading(stasisProject === null || stasisProject === undefined), [stasisProject]);
    useEffect(() => setError(null), [redirectUri]);

    const addRedirect = () => {
        if (validateURL(redirectUri)) {
            const url = redirectRef.current.value;
            setSettingRedirectUri(true);
            addRedirectUri(sessionId, current, url, stasisProject.id).then(res => {
                if (res.status === 200) {
                    setSettingRedirectUri(false);
                    redirectRef.current.value = '';
                    setStasisProject({...stasisProject, client: {...stasisProject.client, redirects: res.data}});
                    const projectIndex = projects.findIndex(p => p.id === stasisProject.id);
                    if (projectIndex !== -1) {
                        const updatedProjects = [...projects];
                        updatedProjects[projectIndex].client.redirects = res.data;
                        setProjects(updatedProjects);
                    }
                } else {
                    setError(true);
                    setSettingRedirectUri(false);
                }
            });
        } else {
            setError(true);
        }
    }

    const removeRedirect = (index) => {
        removeRedirectUri(sessionId, current, index, stasisProject.id).then(res => {
            const uris = [...stasisProject.client.redirects];
            uris.splice(index, 1);

            setStasisProject({...stasisProject, client: {...stasisProject.client, redirects: uris}});
            const projectIndex = projects.findIndex(p => p.id === stasisProject.id);
            if (projectIndex !== -1) {
                const updatedProjects = [...projects];
                updatedProjects[projectIndex].client.redirects = uris;
                setProjects(updatedProjects);
            }
        });
    }

    return (
        <div className={baseStyles.content}>
            <h1 className={[headers['header-1'], headers['no-bottom-margin']].join(' ').trim()}>{'OAuth'}</h1>
            <p>{'Utilize OAuth2 for user authentication and accessing Xenfuma APIs on behalf of users'}</p>
            <h2 className={styles.sectionTitle}>{'Client Information'}</h2>
            <section className={baseStyles.section}>  
                <div className={styles.item}>
                    <div className={styles.label}>{'Client Id'}</div>
                    <SkeletonContent loading={loading}>
                        <div className={styles.value}>{loading ? "************************************************" : stasisProject.client.id}</div>
                    </SkeletonContent>
                    <PrimaryButton isLoading={loading} label={copied ? 'Copied' : 'Copy'} className={[styles.button, styles.copy, copied ? styles.copied : null].join(' ').trim()} contentClassName={styles.buttonContent} onClick={() => {
                        navigator.clipboard.writeText(stasisProject.client.id);
                        setCopied(true);
                        if (copiedTimeout) clearTimeout(copiedTimeout);
                        const i = setTimeout(() => {
                            setCopied(false);
                            setCopiedTimeout(null);
                        }, 1500);
                        setCopiedTimeout(i);
                    }}/>
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>{'Client Secret'}</div>
                    { secret ? <div className={styles.value}>{secret}</div> : null }
                    <PrimaryButton isLoading={loading || resettingSecret} label={!secret ? 'Reset Secret' : secretCopied ? 'Copied' : 'Copy'} className={[styles.button, !secret ? styles.reset : styles.copy, secretCopied ? styles.copied : null].join(' ').trim()} contentClassName={styles.buttonContent} onClick={() => {
                        if (!secret) {
                            setResettingSecret(true);
                            resetSecret(sessionId, current, stasisProject.id).then(res => {
                                setSecret(res.data.secret);
                                setResettingSecret(false);
                            });
                        } else {
                            navigator.clipboard.writeText(secret);
                            setSecretCopied(true);
                            if (secretCopiedTimeout) clearTimeout(secretCopiedTimeout);
                            const i = setTimeout(() => {
                                setSecretCopied(false);
                                setSecretCopiedTimeout(null);
                            }, 1500);
                            setSecretCopiedTimeout(i); 
                        }
                    }}/>
                </div>
            </section>
            <h2 className={[styles.sectionTitle, styles.subtitled].join(' ').trim()}>{'Redirect URIs'}</h2>
            <p className={styles.headingSubtitle}>{'When authenticating, you\'ll need to specify a Redirect URI. This URI must match one specified here exactly.'}</p>
            <div className={styles.actions}>
                <div className={[styles['input-wrapper'], error ? styles.error : null].join(' ').trim()}>
                    <input disabled={settingRedirectUri} className={styles.input} placeholder={'https://example.com/oauth'} onChange={e => setRedirectUri(e.target.value)} ref={redirectRef} onKeyDown={e => {
                        if (e.key === 'Enter') 
                            addRedirect();
                    }}/>
                    <Loader className={styles.loader}/>
                </div>
                <PrimaryButton isLoading={settingRedirectUri} label={'Add'} className={styles.add} onClick={() => addRedirect()}/>
            </div>
            <div className={styles.wrapper}>
                <div className={styles.redirects}>
                    { loading ? <>
                        <RedirectUri url={'*******************************************'} loading={loading}/>
                        <RedirectUri url={'*******************************************'} loading={loading}/>
                        <RedirectUri url={'*******************************************'} loading={loading}/>
                    </> : <>
                        { stasisProject.client.redirects.map((uri, i) => (
                            <RedirectUri key={i} url={uri} loading={loading} onRemove={() => {
                                removeRedirect(i);
                            }}/>
                        )) }
                    </>}
                </div>
            </div>
        </div>
    )
}

const RedirectUri = ({ id, url, loading, onRemove }) => {

    const [removing, setRemoving] = useState(false);

    return (
        <div className={styles.redirect}>
            <div className={styles.url}>
                <SkeletonContent loading={loading}>{url}</SkeletonContent>
            </div>
            <div className={styles.remove} style={{...(loading ? {opacity: 0} : {})}} onClick={() => {
                if (removing) return;
                (onRemove ? onRemove : () => {})();
                setRemoving(true);
            }}>
                { !removing ? <Cross/> : <Loader className={styles.loader}/> }
            </div>
        </div>
    )
}

export default OAuth;