import { useEffect, useState } from 'react';
import styles from '../../assets/css/dialog.module.css';
import Input from '../../components/Input';
import { useStore } from '../../assets/js/Store';
import { DangerButton, SecondaryButton } from '../../components/Buttons';
import { useCurrentProject } from '../../assets/js/Api';

export const DeleteProject = ({ close, onDelete }) => {

    const setDialog = useStore((state) => state.setDialog);
    const currentProject = useCurrentProject();
    const setCloseDialog = useStore((state) => state.setCloseDialog);
    const closeFunction = useStore((state) => state.closeDialog);

    const [loading, setLoading] = useState(false);
    const [projectName, setProjectName] = useState('');
    const [canDelete, setCanDelete] = useState(false);

    useEffect(() => {

        if (closeFunction !== undefined) setCloseDialog(closeFunction);

    }, [setCloseDialog, close, closeFunction]);

    useEffect(() => setCanDelete(currentProject && projectName === currentProject.name.replaceAll(' ', '-').toLowerCase()), [projectName]);

    return ( 
        <div className={[styles.dialog].join(' ').trim()}>
            <h2>{`Delete Project`}</h2>
            <p className={styles.disclaimer}>
                <span>{`To confirm, please type the project name "${currentProject ? (currentProject.name.replaceAll(' ', '-').toLowerCase()) : null}" below.`}</span>
            </p>
            <Input className={styles.input} onChange={v => setProjectName(v)}/>
            <div className={styles.footer}>
                <div className={styles['button-tray']}>
                    <SecondaryButton className={styles.button} label={'Cancel'} onClick={close ? close : () => setDialog(null)} disabled={loading}/>
                    <DangerButton className={styles.button} label={'Delete'} isLoading={loading} disabled={!canDelete} onClick={() => {
                        setLoading(true);
                        (onDelete ? onDelete : () => {})(setLoading);
                    }}/>
                </div>
            </div>
        </div>
    )

}

export default DeleteProject;