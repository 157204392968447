import { Link } from 'react-router-dom';
import styles from '../assets/css/sections.module.css';
import itemStyles from '../assets/css/sectionitem.module.css';
import SkeletonContent from './SkeletonContent';

export const Section = ({children, className, label}) => {

    return (
    <>
        { label ? <div className={styles.label}>{label}</div> : null}
        <div className={[styles.section, className].join(' ').trim()}>
            {children}
        </div>
    </>)

}

export const Item = ({label, value, link, placeholder, loading, onClick}) => {
    return <>
        { link ? <>
            <Link to={link} className={itemStyles.wrapper} draggable={false}>
                <div className={itemStyles.item}>
                    <div className={itemStyles.label}>{label}</div>
                    <SkeletonContent loading={loading}>
                        <div className={itemStyles.value}>{loading ? placeholder : value}</div>
                    </SkeletonContent>
                </div>
            </Link>
        </> : <>
            <div className={itemStyles.wrapper} draggable={false} onClick={onClick} style={onClick ? {cursor: 'pointer'} : null}>
                <div className={itemStyles.item}>
                    <div className={itemStyles.label}>{label}</div>
                    <SkeletonContent loading={loading}>
                        <div className={itemStyles.value}>{loading ? placeholder : value}</div>
                    </SkeletonContent>
                </div>
            </div>
        </>}
    </>
}