
import { useEffect, useState } from 'react';
import { useStore } from '../js/Store';
import Config from '../../config.json';
import { validateURL } from './Utils';

let fetching = {
    session: false,
    projects: false,
    invitations: false
}

const makeRequest = (url, options={}, session_id=null, current=null) => {

    if (options.headers === null || options.headers === undefined) options.headers = {};

    const sessionId = session_id;
    const currentLogin = current;

    const auth = `${sessionId}.${currentLogin}`;
    options.headers['Authorization'] = `Login ${auth}`;

    return new Promise((resolve, reject) => {

        fetch(url, options)
        .then((res) => res.json())
        .then((res) => resolve(res))
        .catch((res) => reject(res));

    });

}

export const fetchSession = (session_id, current) => makeRequest(`${Config.ACCOUNTS_API_ENDPOINT}/api/v1/session`, {}, session_id, current);
export const fetchProjects = (session_id, current) => makeRequest(`${Config.API_ENDPOINT}/api/v1/projects`, {}, session_id, current);
export const fetchInvitations = (session_id, current) => makeRequest(`${Config.API_ENDPOINT}/api/v1/invitations`, {}, session_id, current);

export const useSession = () => {

    const session = useStore((state) => state.session);
    const sessionId = useStore((state) => state.sessionId);
    const current = useStore((state) => state.current);
    const setSession = useStore((state) => state.setSession);
    const setAuthenticated = useStore((state) => state.setAuthenticated);

    useEffect(() => {
        if (!fetching.session && sessionId !== undefined && current !== undefined && session === null) {
            (async () => {
                fetching.session = true;
                try {
                    const res = await fetchSession(sessionId, current);
                    if (res.status === 200) {
                        setSession(res.data);
                    } else {
                        setSession(undefined);
                    }
                } finally {
                    setAuthenticated(true);
                    fetching.session = false;
                }
            })();
        }
    }, [sessionId, current, session, setSession, setAuthenticated]);

    return session;

}

export const useProjects = () => {

    const session = useSession();
    const sessionId = useStore((state) => state.sessionId);
    const current = useStore((state) => state.current);
    const projects = useStore((state) => state.projects);
    const setProjects = useStore((state) => state.setProjects);

    useEffect(() => {
        if ((session !== null && session !== undefined) && (projects === null) && !fetching.projects){
            fetching.projects = true;
            fetchProjects(sessionId, current).then((res) => {
                if (res.status === 200){
                    setProjects(res.data);
                    fetching.projects = false;
                }
            });
        }
    }, [setProjects, sessionId, current, session, projects]);

    return projects;
}

export const useInvitations = () => {

    const session = useSession();
    const sessionId = useStore((state) => state.sessionId);
    const current = useStore((state) => state.current);
    const invitations = useStore((state) => state.invitations);
    const setInvitations = useStore((state) => state.setInvitations);

    useEffect(() => {
        if ((session !== null && session !== undefined) && (invitations === null) && !fetching.invitations){
            fetching.invitations = true;
            fetchInvitations(sessionId, current).then((res) => {
                if (res.status === 200){
                    setInvitations(res.data);
                    fetching.invitations = false;
                }
            });
        }
    }, [setInvitations, sessionId, current, session, invitations]);

    return invitations;
    
}

export const useCurrentProject = () => {

    const projects = useProjects();
    const selectedProjectId = useStore((state) => state.selectedProject);
    const [selectedProject, setSelectedProject] = useState(null);

    useEffect(() => {

        if (!projects || !selectedProjectId) return;
        setSelectedProject(projects.filter(p => p.id === selectedProjectId)[0]);

    }, [projects, selectedProjectId]);

    return selectedProject;
}

export const setIcon = async (session, current, projectId, iconData) => {

    const byteString = atob(iconData.split(',')[1]);
    const mimeString = iconData.split(',')[0].split(':')[1].split(';')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) 
        uintArray[i] = byteString.charCodeAt(i);

    const blob = new Blob([arrayBuffer], { type: mimeString });
    const data = new FormData();
    data.append('icon', blob, 'icon.png');

    const res = await makeRequest(`${Config.API_ENDPOINT}/api/v1/projects/${projectId}`, {
        method: 'PATCH',
        body: data
    }, session, current);

    return res;

}

export const resetSecret = async (session, current, projectId) => {

    const res = await makeRequest(`${Config.API_ENDPOINT}/api/v1/projects/${projectId}/secret`, { method: 'POST' }, session, current);
    return res;

}

export const addRedirectUri = async (session, current, redirectUri, projectId) => {

    const res = await makeRequest(`${Config.API_ENDPOINT}/api/v1/projects/${projectId}/redirects`, {
        method: 'POST',
        body: JSON.stringify({
            url: redirectUri
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    }, session, current);

    return res;

}

export const removeRedirectUri = async (session, current, redirectIndex, projectId) => {

    const res = await makeRequest(`${Config.API_ENDPOINT}/api/v1/projects/${projectId}/redirects`, {
        method: 'DELETE',
        body: JSON.stringify({
            url: redirectIndex
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    }, session, current);

    return res;

}

export const updateProjectDetails = async (session, current, project, changes) => {

    const errors = {}

    const payload = {};
    Object.keys(changes).forEach(k => {
        if (changes[k] !== null) {
            console.log(k)
            if (['website_uri', 'privacy_policy_uri', 'terms_of_service_uri'].includes(k)) {
                if (validateURL(changes[k])) {
                    payload[k] = changes[k];
                } else {
                    errors[k] = 'Invalid URI';
                }
            } else {
                payload[k] = changes[k];
            }
        }
    });

    if (Object.keys(errors).length > 0) 
        throw errors;

    const res = await makeRequest(`${Config.API_ENDPOINT}/api/v1/projects/${project.id}`, {
        method: 'PATCH',
        body: JSON.stringify(payload),
        headers: { 'Content-Type': 'application/json' }
    }, session, current);

    return res;
}

export const removeMember = async (session, current, member, projectId) => {

    const res = await makeRequest(`${Config.API_ENDPOINT}/api/v1/projects/${projectId}/members`, {
        method: 'DELETE',
        body: JSON.stringify({
            member: member.id
        }),
        headers: { 'Content-Type': 'application/json' }
    }, session, current);

    return res;

}

export const addMember = async (session, current, identifier, projectId) => {

    const res = await makeRequest(`${Config.API_ENDPOINT}/api/v1/projects/${projectId}/members`, {
        method: 'POST',
        body: JSON.stringify({
            identifier: identifier
        }),
        headers: { 'Content-Type': 'application/json' }
    }, session, current);

    return res;

}

export const deleteProject = async (session, current, projectId) => {

    const res = await makeRequest(`${Config.API_ENDPOINT}/api/v1/projects/${projectId}`, {
        method: 'DELETE'
    }, session, current);

    return res;

}

export const resetTokens = async (session, current, projectId) => {

    const res = await makeRequest(`${Config.API_ENDPOINT}/api/v1/projects/${projectId}/tokens`, {
        method: 'DELETE'
    }, session, current);

    return res;

}

export const acceptInvite = async (session, current, invite) => {

    const res = await makeRequest(`${Config.API_ENDPOINT}/api/v1/invitations/${invite}`, {
        method: 'POST'
    }, session, current);

    return res;

}

export const declineInvite = async (session, current, invite) => {

    const res = await makeRequest(`${Config.API_ENDPOINT}/api/v1/invitations/${invite}`, {
        method: 'DELETE'
    }, session, current);

    return res;

}

export const createProject = async (session, current, details) => {

    const errors = {}

    const payload = {};
    Object.keys(details).forEach(k => {
        if (details[k] !== null) {
            console.log(k)
            if (['website_uri', 'privacy_policy_uri', 'terms_of_service_uri'].includes(k)) {
                if (validateURL(details[k])) {
                    payload[k] = details[k];
                } else {
                    errors[k] = 'Invalid URI';
                }
            } else {
                payload[k] = details[k];
            }
        }
    });

    if (Object.keys(errors).length > 0) 
        throw errors;

    const res = await makeRequest(`${Config.API_ENDPOINT}/api/v1/projects`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: { 'Content-Type': 'application/json' }
    }, session, current);

    return res;
}