import baseStyles from '../assets/css/views/dashboard.module.css';
import headers from '../assets/css/headers.module.css';
import { Item } from '../components/Section';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useStore } from '../assets/js/Store';
import DeleteProject from './dialogs/DeleteProject';
import { deleteProject, resetTokens, useCurrentProject } from '../assets/js/Api';
import ResetTokens from './dialogs/ResetTokens';
import Error from './dialogs/Error'

export const DangerZone = () => {

    const { project } = useParams();
    const setSelectedProject = useStore((state) => state.setSelectedProject);
    const currentProject = useCurrentProject();
    const setDialog = useStore((state) => state.setDialog);
    const sessionId = useStore((state) => state.sessionId);
    const current = useStore((state) => state.current);

    useEffect(() => {
        if (!project) return;
        setSelectedProject(project);
    }, [project,setSelectedProject]);

    return (
        <div className={baseStyles.content}>
            <h1 className={[headers['header-1'], headers['no-bottom-margin']].join(' ').trim()}>{'Danger Zone'}</h1>
            <p>{'This is the Danger Zone. All actions you take here are irreversibel and can have major consequences.'}</p>
            <section className={baseStyles.section}>  
                <Item label={'Reset Tokens'} value={'Invalidate all tokens associated with this app. This action will immediately revoke all active tokens, requiring users to reauthorize your application.'} onClick={() => {
                    setDialog(<ResetTokens onReset={() => {
                        resetTokens(sessionId, current, currentProject.id).then(res => {
                            if (res.status === 200) 
                                setDialog(null);
                            else
                                setDialog(<Error/>)
                        }).catch(err => {
                            setDialog(<Error/>)
                        });
                    }}/>)
                }}/>
                <Item label={'Delete this project'} value={'Once you delete this project, you won\'t be able to recover it.'} onClick={() => {
                    setDialog(<DeleteProject onDelete={setLoading => {
                        deleteProject(sessionId, current, currentProject.id).then(res => {
                            setLoading(false);
                            if (res.status === 200)
                                window.location = '/';
                        });
                    }}/>)
                }}/>
            </section>
        </div>
    )
}

export default DangerZone;