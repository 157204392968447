import baseStyles from '../assets/css/views/dashboard.module.css';
import styles from '../assets/css/views/overview.module.css';
import headers from '../assets/css/headers.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import SkeletonContent from '../components/SkeletonContent';
import { useEffect, useRef, useState } from 'react';
import Input from '../components/Input';
import TextArea from '../components/TextArea';
import Icon from './dialogs/Icon';
import { useStore } from '../assets/js/Store';
import ProjectIcon from '../assets/svgs/ProjectIcon';
import { createProject, setIcon, updateProjectDetails, useCurrentProject } from '../assets/js/Api';
import { PrimaryButton, SecondaryButton } from '../components/Buttons';

export const Create = () => {

    const iconRef = useRef(null);
    const setDialog = useStore((state) => state.setDialog);
    const projects = useStore((state) => state.projects);
    const setProjects = useStore((state) => state.setProjects);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [state, setState] = useState(Math.random());
    const [iconData, setIconData] = useState(null);
    const [values, setValues] = useState({
        name: null,
        description: null,
        website_uri: null,
        privacy_policy_uri: null,
        terms_of_service_uri: null
    });
    const [errors, setErrors] = useState({
        name: null,
        description: null,
        website_uri: null,
        privacy_policy_uri: null,
        terms_of_service_uri: null
    });

    const sessionId = useStore((state) => state.sessionId);
    const current = useStore((state) => state.current);
    const navigate = useNavigate();

    return (
        <div className={baseStyles.content}>
            <h1 className={[headers['header-1'], headers['no-bottom-margin']].join(' ').trim()}>{'Create Project'}</h1>
            <p>{'Please provide general information about your project.'}</p>
            <section className={baseStyles.section}>  
                <div className={styles.heading}>
                    <div className={styles['icon-container']}>
                        <SkeletonContent loading={loading} className={styles.icon}>
                            { iconData ? (
                                <img className={styles.icon} src={iconData} alt="App Icon" onClick={() => iconRef.current.click()}/>
                            ) : (
                                <div className={styles.icon} onClick={() => iconRef.current.click()}>
                                    <ProjectIcon/>
                                </div>
                            )}
                        </SkeletonContent>
                    </div>
                    <div className={styles.details}>
                        <div className={styles.item}>
                            <div className={styles.label}>{'Project Name (required)'}</div>
                            <SkeletonContent loading={loading} className={styles.skeleton} wrapperClassName={styles.content}>
                                <Input className={styles.input} onFocus={() => setErrors({...errors, name: false})} error={errors.name} state={state} disabled={saving} placeholder={'Unnamed Project'} onChange={v => {
                                    setValues({...values, name: v});
                                }}/>
                            </SkeletonContent>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.label}>{'Description'}</div>
                            <SkeletonContent loading={loading} className={styles.skeleton} wrapperClassName={styles.content}>
                                <TextArea className={styles.input} onFocus={() => setErrors({...errors, description: false})} error={errors.description} state={state} disabled={saving} onChange={v => {
                                    setValues({...values, description: v});
                                }}/>
                            </SkeletonContent>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.label}>{'Website URL'}</div>
                            <SkeletonContent loading={loading} className={styles.skeleton} wrapperClassName={styles.content}>
                                <Input className={styles.input} onFocus={() => setErrors({...errors, website_uri: false})} error={errors.website_uri} state={state} disabled={saving} placeholder={'https://example.com'} onChange={v => {
                                    setValues({...values, website_uri: v});
                                }}/>
                            </SkeletonContent>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.label}>{'Privacy Policy URL'}</div>
                            <SkeletonContent loading={loading} className={styles.skeleton} wrapperClassName={styles.content}>
                                <Input className={styles.input} onFocus={() => setErrors({...errors, privacy_policy_uri: false})} error={errors.privacy_policy_uri} state={state} disabled={saving} placeholder={'https://example.com/privacy-policy'} onChange={v => {
                                    setValues({...values, privacy_policy_uri: v});
                                }}/>
                            </SkeletonContent>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.label}>{'Terms of Service URL'}</div>
                            <SkeletonContent loading={loading} className={styles.skeleton} wrapperClassName={styles.content}>
                                <Input className={styles.input} onFocus={() => setErrors({...errors, terms_of_service_uri: false})} error={errors.terms_of_service_uri} state={state} disabled={saving} placeholder={'https://example.com/terms-of-service'} onChange={v => {
                                    setValues({...values, terms_of_service_uri: v});
                                }}/>
                            </SkeletonContent>
                        </div>
                        <div className={styles['button-tray']}>
                            <PrimaryButton label={'Create'} isLoading={saving} onClick={() => {
                                setSaving(true);
                                createProject(sessionId, current, values).then(res => {
                                    setErrors({});
                                    if (res.status === 200) {
                                        if (iconData) {
                                            setIcon(sessionId, current, res.data.id, iconData).then(iRes => {
                                                setProjects([res.data, ...projects]);
                                                window.location.href = `/${res.data.id}/overview`;
                                            });
                                        } else {
                                            setProjects([res.data, ...projects]);
                                            navigate(`/${res.data.id}/overview`);
                                        }
                                    } else {
                                        setSaving(false);
                                        const copy = {};
                                        Object.keys(res.errors).forEach(k => copy[k] = true);
                                        setErrors(copy);
                                    }
                                }).catch(err => {
                                    setSaving(false);
                                    const copy = {};
                                    Object.keys(err).forEach(k => {
                                        copy[k] = true;
                                    });
                                    setErrors(copy);
                                });
                            }}/>
                            <SecondaryButton label={'Cancel'} disabled={saving} onClick={() => {
                                navigate('/');
                            }}/>
                        </div>
                    </div>
                </div>
            </section>
            <input ref={iconRef} type={'file'} accept=".png,.gif,.jpeg,.jpg" style={{display: 'none'}} id={'avatar-input'} onChange={(e) => {
                const file = e.target.files[0];
                setDialog(<Icon image={file} onSubmit={icon => {
                    return new Promise((resolve, reject) => {
                        setIconData(icon);
                        resolve(true);
                    });
                }}/>);
            }}/>
        </div>
    )
}

export default Create;