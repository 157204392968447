import { useRoutes } from "react-router-dom";
import { Base } from "../views/Base";

export const Router = (props) => {
    const router = useRoutes([
        {
            element: <Base/>,
            path: '*',
        }
    ]);
    return router;
}
