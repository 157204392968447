import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import styles from '../assets/css/inputs.module.css';

export const Input = forwardRef(({ type, state, error, value, className, wrapperClassName, onPaste, onChange, onFocus, onSubmit, disabled, inputMode, onKeyDown, maxLength, placeholder, loading }, ref) => {

    const [focused, setFocused] = useState(false);
    const [empty, setEmpty] = useState(true);
    const [_value, setValue] = useState(value);
    const [_error, setError] = useState(null);
    const inputRef = useRef(null);

    useEffect(() => setError(error), [error])
    useEffect(() => setEmpty(_value === null || _value === undefined || _value.length === 0), [_value]);
    useEffect(() => {
        if (!inputRef.current) return;
        inputRef.current.value = (value ? value : '').trim();
        setEmpty(value === null || value === undefined || value.length === 0);
    }, [value, ref, state]);

    useImperativeHandle(ref, () => ({
        inputRef: inputRef
    }));

    return (
        <div className={[className, styles.container, focused ? styles.focused : null, !empty ? styles['not-empty'] : null, error ? styles.error : null].join(' ').trim()}>
            <div className={[styles.wrapper, wrapperClassName].join(' ').trim()}>
                <input onSubmit={onSubmit} onPaste={onPaste} disabled={disabled} inputMode={inputMode} onKeyDown={e => {
                    if (e.key === 'Enter')
                        (onSubmit ? onSubmit : () => {})(e);
                    else
                        (onKeyDown ? onKeyDown : () => {})(e);
                }} type={type} maxLength={maxLength} ref={inputRef} onChange={(event) => {
                    setEmpty(event.currentTarget.value.length === 0);
                    (onChange ? onChange : () => {})(event.currentTarget.value);
                }} onFocus={() => {
                    (onFocus ? onFocus : () => {})();
                    setFocused(true);
                }} onBlur={() => setFocused(false)}/>
                <div className={styles.placeholder}>{placeholder ? placeholder : ''}</div>
            </div>
            { styles.error ? <>
                <div className={styles.error}>
                    <div className={styles.label}>{_error}</div>
                </div>
            </> : null}
            
        </div>
    )
    
})

export default Input;