import { useState, useEffect, useRef } from 'react';
import styles from '../assets/css/projectselector.module.css';
import Caret from '../assets/svgs/Caret';
import { Loader } from './Loader';
import { useCurrentProject, useProjects } from '../assets/js/Api';
import { useStore } from '../assets/js/Store';
import ProjectIcon from '../assets/svgs/ProjectIcon';
import { useNavigate } from 'react-router-dom';
import Plus from '../assets/svgs/Plus';

export const ProjectSelector = ({ className }) => {

    const navigate = useNavigate();

    const ref = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const selectProject = useStore((state) => state.setSelectedProject);
    const currentProject = useCurrentProject();
    
    const projects = useProjects();

    const [selectedProject, setSelectedProject] = useState(null);

    useEffect(() => setSelectedProject(currentProject), [currentProject]);

    useEffect(() => {
        setItems(projects ? projects.map((project, i) => ({
            id: project.id,
            name: project.name,
            icon: project.icon
        })) : []);
    }, [projects]);

    useEffect(() => {
        setLoading(projects === null || projects === undefined);
    }, [selectedProject, projects])

    useEffect(() => {
        if (!isLoading && projects && projects.length > 0 && selectedProject === null && !localStorage.getItem('selected_project'))
            selectProject(projects[0].id);
    }, [isLoading, projects, selectedProject]);

    const [items, setItems] = useState(projects ? projects.map((project, i) => ({
        id: project.id,
        name: project.name,
        icon: project.icon
    })) : []);

    useEffect(() => {
        const clickOut = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setExpanded(false);
            }
        };
        document.addEventListener('click', clickOut, true);
        return () => {
            document.removeEventListener('click', clickOut, true);
        };
    }, []);

    return (
        <div ref={ref} className={[styles.container, expanded ? styles.focus : null, className].join(' ').trim()}>
            { isLoading || selectedProject ? <>
                <Project 
                    className={[isLoading ? styles.loading : null, styles.selected].join(' ').trim()}
                    name={selectedProject ? selectedProject.name : ''}
                    src={selectedProject && selectedProject.icon ? `https://cdn.xenfuma.com/icons/dev-projects/${selectedProject.icon}.png` : null}
                    loading={isLoading}
                    onClick={() => {
                        if (!isLoading){
                            setExpanded(!expanded);
                        }
                    }} 
                    icon={<Caret/>}
                />
            </> : <>
            <div className={[styles.option, styles.selected].join(' ').trim()} onClick={() => navigate('/create')}>
                <div className={styles.details}>
                    <div className={[styles.icon, styles.create].join(' ').trim()}>
                        <Plus className={styles['plus']}/>
                    </div>
                    <div className={styles.label}>{'Create a new project'}</div>
                </div>
            </div>
            </>}
            <Loader color={"rgba(255,255,255,0.25)"} className={[styles.loader, isLoading ? styles.loading : ''].join(' ').trim()}/>
            <div className={[styles.options, expanded ? styles.expanded : ''].join(' ').trim()}>
                {items.map((project, index) => 
                    <Project 
                        key={project.id}
                        name={project.name}
                        src={project.icon != null ? `https://cdn.xenfuma.com/icons/dev-projects/${project.icon}.png` : null}
                        onClick={() => {
                            setExpanded(false);
                            localStorage.setItem('selected_project', project.id);
                            selectProject(project.id);
                            const parts = window.location.pathname.split('/');
                            if (parts.length === 3)
                                navigate(`/${project.id}/${parts[2]}`, { replace: true });
                        }}
                        icon={null}
                    />
                )}
                <div className={[styles.option, styles.create].join(' ').trim()} onClick={() => {
                    setExpanded(false);
                    navigate('/create');
                }}>
                    <div className={styles.details}>
                        <div className={[styles.icon, styles.create].join(' ').trim()}>
                            <Plus className={styles['plus']}/>
                        </div>
                        <div className={styles.label}>{'Create a new project'}</div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export const Project = ( { style, className, onClick, src, icon, name, loading } ) => {

    const [iconLoaded, setIconLoaded] = useState(false);
    return (
        <div style={style} className={[styles.option, className ? className : ''].join(' ').trim()} onClick={onClick || function(){}}>
            <div className={styles.details}>
                <div className={styles.icon}>
                    { loading ? <>
                        <Loader className={styles.loader} size={24}/>
                    </> : src ? (
                        <img onLoad={() => setIconLoaded(true)} src={src} style={ iconLoaded ? {opacity: 1} : {opacity: 0} }/>
                    ) : (
                        <ProjectIcon className={styles['project-icon']}/>
                    )}
                </div>
                <div className={styles.label} style={icon != null ? {width: 'calc(100% - 20px)'} : {}}>{name ? name : ''}</div>
                {icon}
            </div>
        </div>
    )
}

export const ServerIcon = ( { className, label, svg, src } ) => {

    const [loaded, setLoaded] = useState(false);

    return (
        <div className={[styles['project-icon'], className ? className : ''].join(' ').trim()} data-label={label ? label : 'No Label'}>
            { svg ? (
                svg
            ) : src != null ?  (
                <img alt="" onLoad={() => {setLoaded(true)}} src={src} style={ loaded ? {opacity: 1} : {opacity: 0} }/>
            ) : <></>}
        </div>
    )

}
