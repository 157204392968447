import { useEffect } from 'react';
import styles from '../../assets/css/dialog.module.css';
import { useStore } from '../../assets/js/Store';
import { SecondaryButton } from '../../components/Buttons';

export const Error = (props) => {

    const setDialog = useStore((state) => state.setDialog);
    const setCloseDialog = useStore((state) => state.setCloseDialog);
    const close = useStore((state) => state.closeDialog);

    useEffect(() => {

        if (props.close !== undefined) setCloseDialog(props.close);

    }, [setCloseDialog, close, props.close]);

    return (
        <div className={styles.dialog}>
            <h2>{`Error`}</h2>
            <p className={styles.subtitle}>{props.subtitle ? props.subtitle : 'An unexpected error occurred.'}</p>
            { props.message ? <p>{props.message}</p> : null }
            <div className={styles['button-tray']}>
                <SecondaryButton label={'Ok'} onClick={close ? close : () => setDialog(null)}/>
            </div>
        </div>
    )

}

export default Error;