import './assets/fonts/rubik/rubik.css';
import './assets/css/colors.css';
import './assets/css/index.css';

import { Router } from './components/Router';
import IdentityProvider from './components/IdentityProvider';
import { useStore } from './assets/js/Store';

function App() {

	const setSessionId = useStore((state) => state.setSessionId);
	const setCurrent = useStore((state) => state.setCurrent);

	return (<>
		<IdentityProvider onLoad={({session, current}) => {
			setSessionId(session);
			setCurrent(current);
		}}/>
		<Router/>
	</>);
}

export default App;
