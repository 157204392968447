import styles from '../assets/css/views/dashboard.module.css';
import headers from '../assets/css/headers.module.css';
import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { acceptInvite, declineInvite, fetchProjects, useInvitations, useProjects } from '../assets/js/Api';
import ProjectIcon from '../assets/svgs/ProjectIcon';
import SkeletonContent from '../components/SkeletonContent';
import { PrimaryButton, SecondaryButton } from '../components/Buttons';
import { useStore } from '../assets/js/Store';
import Error from '../views/dialogs/Error';
import Plus from '../assets/svgs/Plus';

export const Dashboard = () => {

    const projects = useProjects();
    const invitations = useInvitations();
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(projects === null || projects === undefined || invitations === null || invitations === undefined);
    }, [projects, invitations]);

    const contentRef = useCallback(node => {
        if (node !== null) document.body.setAttribute('style', `--window-height: ${window.innerHeight}px; --scrollbar-width: ${node.offsetWidth - node.clientWidth}px!important;`);
    }, []);

    return (
        <div className={styles.content} ref={contentRef}>
            <SkeletonContent loading={loading}>
                <h1 className={[headers['header-1'], headers['no-bottom-margin']].join(' ').trim()}>{'Projects'}</h1>
            </SkeletonContent>
            <SkeletonContent loading={loading}>
                <p>{'These are the projects you have created or in which you are a contributor.'}</p>
            </SkeletonContent>
            <div className={styles.projects}>
                { !loading ? (projects && projects.length === 0) ? <>
                    <div className={[styles.project, styles.create].join(' ').trim()} onClick={() => navigate('/create')}>
                        <div className={styles.info}>
                            <div className={styles.icon}>
                                <Plus className={styles.create}/>
                            </div>
                            <div className={styles.details}>
                                <div className={styles.title}>{'Create a new project'}</div>
                                <div className={styles.description}>{'To begin, you\'ll need to create or join a developer project.'}</div>
                            </div>
                        </div>
                    </div>
                </> : projects.map((project, i) => (
                    <Project 
                        id={project.id}
                        key={project.id}
                        name={project.name} 
                        owner={project.creator.username}
                        icon={project.icon ? `https://cdn.xenfuma.com/icons/dev-projects/${project.icon}.png`: null}
                        contributors={[
                            { avatar : project.creator.avatar, username: project.creator.username, role: 1 }, 
                            ...project.members.map((member, i) => ({ avatar: member.user.avatar, username: member.user.username, role: member.role }))
                        ]}
                    />
                )) : <>
                    <SkeletonContent className={styles.project} wrapperClassName={styles.skeleton} loading={true}/>
                    <SkeletonContent className={styles.project} wrapperClassName={styles.skeleton} loading={true}/>
                </>}
            </div>
            <SkeletonContent loading={loading} style={!loading && invitations.length === 0 ? {display: 'none'} : null}>
                <h1 className={[headers['header-2'], headers['tm-64'], headers['no-bottom-margin']].join(' ').trim()}>{'Invitations'}</h1>
            </SkeletonContent>
            <SkeletonContent loading={loading} style={!loading && invitations.length === 0 ? {display: 'none'} : null}>
                <p>{'You have been invited to join the following projects as a contributor.'}</p>
            </SkeletonContent>
            <div className={styles.projects}>
                { !loading ? invitations.map((invitation, i) => (
                    <Project 
                        id={invitation.id}
                        key={invitation.id}
                        name={invitation.project.name} 
                        owner={invitation.project.creator.username}
                        icon={invitation.project.icon ? `https://cdn.xenfuma.com/icons/dev-projects/${invitation.project.icon}.png`: null}
                        contributors={[
                            { avatar : invitation.project.creator.avatar, username: invitation.project.creator.username, role: 1 }, 
                            ...invitation.project.members.map((member, i) => ({ avatar: member.user.avatar, username: member.user.username, role: member.role }))
                        ]}
                        invitation={true}
                    />
                )) : <>
                    <SkeletonContent className={styles.project} wrapperClassName={styles.skeleton} loading={true}/>
                </>}
            </div>
        </div>
    )

}

const Project = ({ id, name, icon, owner, verified, contributors, invitation }) => {

    const [action, setAction] = useState(false);
    const sessionId = useStore((state) => state.sessionId);
    const current = useStore((state) => state.current);
    const invitations = useInvitations();
    const setInvitations = useStore((state) => state.setInvitations);
    const setProjects = useStore((state) => state.setProjects);
    const setDialog = useStore((state) => state.setDialog);

    return (
        invitation ? (
            <div className={[styles.project, styles.invite].join(' ').trim()}>
                <div className={styles.info}>
                    <div className={styles.icon}>
                        { icon ? (
                            <img alt={''} src={icon}/>
                        ) : (
                            <ProjectIcon/>
                        )}
                    </div>
                    <div className={styles.details}>
                        <div className={styles.name}>{name ? name : 'Unnamed Project'}</div>
                        <div className={styles.owner}>{owner ? owner : 'Unknown'}</div>
                        <div className={styles.members}>{`${contributors.filter(c => c.role !== 3).length} contributor${contributors.filter(c => c.role !== 3).length === 1 ? '' : 's'}`}</div>
                        <div className={styles.invite}>
                            <PrimaryButton 
                                disabled={action && action !== 'accepting'} 
                                isLoading={action && action === 'accepting'} 
                                label={'Accept'} 
                                className={styles.button} 
                                contentClassName={styles.wrapper} 
                                onClick={() => {
                                    setAction('accepting');
                                    acceptInvite(sessionId, current, id).then(res => {
                                        if (res.status === 200) {
                                            fetchProjects(sessionId, current).then(res => {
                                                if (res.status === 200){
                                                    setProjects(res.data);
                                                    setInvitations([...invitations.filter(i => i.id !== id)]);
                                                    setAction(null);
                                                }
                                            });
                                        } else {
                                            setDialog(<Error/>);
                                            setAction(null);
                                        }
                                    });
                                }}
                            />
                            <SecondaryButton 
                                disabled={action && action !== 'declining'} 
                                isLoading={action && action === 'declining'} 
                                label={'Decline'} 
                                className={styles.button} 
                                contentClassName={styles.wrapper}
                                onClick={() => {
                                    setAction('declining');
                                    declineInvite(sessionId, current, id).then(res => {
                                        if (res.status === 200) {
                                            fetchProjects(sessionId, current).then(res => {
                                                if (res.status === 200){
                                                    setProjects(res.data);
                                                    setInvitations([...invitations.filter(i => i.id !== id)]);
                                                    setAction(null);
                                                }
                                            });
                                        } else {
                                            setDialog(<Error/>);
                                            setAction(null);
                                        }
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>            
            </div>
        ) : (
            <Link to={`/${id}/overview`} draggable={false} onClick={() => localStorage.setItem('selected_project', id)}>
                <div className={[styles.project].join(' ').trim()}>
                    <div className={styles.info}>
                        <div className={styles.icon}>
                            { icon ? (
                                <img alt={''} src={icon}/>
                            ) : (
                                <ProjectIcon/>
                            )}
                        </div>
                        <div className={styles.details}>
                            <div className={styles.name}>{name ? name : 'Unnamed Project'}</div>
                            <div className={styles.owner}>{owner ? owner : 'Unknown'}</div>
                            <div className={styles.members}>{`${contributors.filter(c => c.role !== 3).length} contributor${contributors.filter(c => c.role !== 3).length === 1 ? '' : 's'}`}</div>
                            <div className={styles.contributors}>
                                { (contributors ? contributors.filter(c => c.role !== 3) : []).map((contributor, i) => {
                                    return (
                                        <div key={i} className={styles.contributor}>
                                            <img alt={``} src={`https://cdn.xenfuma.com/avatars/${contributor.avatar}.png`}/>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>            
                </div>
            </Link>
        )
    )
}

export default Dashboard;