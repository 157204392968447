import { useEffect, useRef, useState } from 'react';
import styles from '../../assets/css/dialogs/icon.module.css';
import dStyles from '../../assets/css/dialog.module.css';
import { useStore } from '../../assets/js/Store';
import { PrimaryButton, SecondaryButton } from '../../components/Buttons';

import { Cropper } from '../../components/Cropper';
import { setIcon } from '../../assets/js/Api';

export const Icon = ({ image, close, onSubmit }) => {

    const setDialog = useStore((state) => state.setDialog);
    const setCloseDialog = useStore((state) => state.setCloseDialog);
    const closeFunction = useStore((state) => state.closeDialog);
    const sessionId = useStore((state) => state.sessionId);
    const current = useStore((state) => state.current);
    const cropperRef = useRef(null);
    
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        if (closeFunction !== undefined) setCloseDialog(closeFunction);

    }, [setCloseDialog, close, closeFunction]);

    return ( 
        <div className={[dStyles.dialog, styles.dialog].join(' ').trim()}>
            <h2>{`Change Icon`}</h2>
            <Cropper image={image} ref={cropperRef}/>
            <div className={styles.footer}>
                <div className={dStyles['button-tray']}>
                    <SecondaryButton className={dStyles.button} label={'Cancel'} onClick={close ? close : () => setDialog(null)} disabled={loading}/>
                    <PrimaryButton className={dStyles.button} label={'Save'} isLoading={loading} onClick={() => {
                        setLoading(true);
                        const icon = cropperRef.current.generateIcon();
                        if (!icon) return;
                        (onSubmit ? onSubmit : () => new Promise((resolve, reject) => resolve(false)))(icon).then(success => {
                            if (success) {
                                setLoading(false);
                                setDialog(null);
                            } else {
                                setLoading(false);
                            }
                        });
                    }}/>
                </div>
            </div>
        </div>
    )

}

export default Icon;