import { useEffect, useRef, useState } from 'react';
import styles from '../../assets/css/dialog.module.css';
import mStyles from '../../assets/css/dialogs/member.module.css';
import { useStore } from '../../assets/js/Store';
import { DangerButton, SecondaryButton } from '../../components/Buttons';

export const RemoveMember = ({ member, close, onRemove }) => {

    const setDialog = useStore((state) => state.setDialog);
    const setCloseDialog = useStore((state) => state.setCloseDialog);
    const closeFunction = useStore((state) => state.closeDialog);

    const [loading, setLoading] = useState(false);

    useEffect(() => {

        if (closeFunction !== undefined) setCloseDialog(closeFunction);

    }, [setCloseDialog, close, closeFunction]);

    return ( 
        <div className={[styles.dialog].join(' ').trim()}>
            <h2>{`Remove Member`}</h2>
            <p className={styles.subtitle}>
                <span>{'Are you sure that you want to remove '}</span>
                <b className={mStyles.member}>{member.user.username}</b>
                <span>{' from this project?'}</span>
            </p>
            <div className={styles.footer}>
                <div className={styles['button-tray']}>
                    <SecondaryButton className={styles.button} label={'Cancel'} onClick={close ? close : () => setDialog(null)} disabled={loading}/>
                    <DangerButton className={styles.button} label={'Remove'} isLoading={loading} onClick={() => {
                        setLoading(true);
                        (onRemove ? onRemove : () => {})(setLoading);
                    }}/>
                </div>
            </div>
        </div>
    )

}

export default RemoveMember;