import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import styles from '../assets/css/textarea.module.css';

export const TextArea = forwardRef(({error, state, value, disabled, type, maxLength, placeholder, className, wrapperClassName, inputMode, onPaste, onKeyDown, onChange}, ref) => {

    const [focused, setFocused] = useState(false);
    const [empty, setEmpty] = useState(true);
    const [_error, setError] = useState(error);

    const textAreaRef = useRef(null);

    useEffect(() => setError(error), [error]);
    useEffect(() => {
        if (!textAreaRef.current) return;
        textAreaRef.current.value = value ? value : '';
        setEmpty(value === null || value === undefined || value.length === 0);
    }, [value, ref, state]);

    useImperativeHandle(ref, () => ({
        textAreaRef: textAreaRef
    }));

    return (
        <div className={[className, styles.container, focused ? styles.focused : null, !empty ? styles['not-empty'] : null].join(' ').trim()}>
            <div className={[styles.wrapper, wrapperClassName].join(' ').trim()}>
                <textarea onPaste={onPaste ? onPaste : () => {}} disabled={disabled} inputMode={inputMode} onKeyDown={onKeyDown ? onKeyDown : () => {}} type={type} maxLength={maxLength} ref={textAreaRef}  onChange={(event) => {
                    setEmpty(event.currentTarget.value.length === 0);
                    (onChange ? onChange : () => {})(event.currentTarget.value);
                }} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)}/>
                <div className={styles.placeholder}>{placeholder ? placeholder : ''}</div>
            </div>
            { _error ? <>
                <div className={styles.error}>
                    <div className={styles.label}>{_error}</div>
                </div>
            </> : null}
            
        </div>
    )
    
})

export default TextArea;