import styles from '../assets/css/views/dashboard.module.css';
import dialogStyles from '../assets/css/dialog.module.css';
import { Profile } from '../components/Profile';
import Sidebar from '../components/Sidebar';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useStore } from '../assets/js/Store';
import { useLocation, Routes, Route } from 'react-router-dom';
import Dashboard from './Dashboard';
import Overview from './Overview';
import Members from './Members';
import OAuth from './OAuth';
import DangerZone from './DangerZone';
import { useProjects } from '../assets/js/Api';
import Create from './Create';

export const Base = (props) => {

    const location = useLocation();

    const dialog = useStore((state) => state.dialog);
    const setDialog = useStore((state) => state.setDialog);
    const close = useStore((state) => state.closeDialog);
    const projects = useProjects();

    return (
        <div className={[styles.container].join(' ').trim()}>
            <Sidebar />
            <TransitionGroup component={null}>
                    <CSSTransition key={location.pathname} appear timeout={400} classNames={{
                        enter: styles['transition-enter'],
                        enterActive: styles['transition-enter-active'],
                        exit: styles['transition-exit'],
                        exitActive: styles['transition-exit-active']
                    }}>
                        <Routes location={location}>
                            <Route path={'/'} element={<Dashboard/>}/>
                            <Route path={'/create'} element={<Create/>}/>
                            <Route path={'/:project/overview'} element={<Overview/>}/>
                            <Route path={'/:project/members'} element={<Members/>}/>
                            <Route path={'/:project/oauth'} element={<OAuth/>}/>
                            <Route path={'/:project/danger-zone'} element={<DangerZone/>}/>
                        </Routes>
                    </CSSTransition>
                </TransitionGroup>
            <Profile/>
            <TransitionGroup onMouseDown={(e) => {
                if (e.currentTarget !== e.target) return;
                (close ? close : () => setDialog(null))();
            }} className={[dialogStyles['dialog-wrapper'], dialog !== undefined && dialog !== null ? dialogStyles.visible : null].join(' ').trim()}>
                {dialog !== undefined && dialog !== null ? <CSSTransition appear timeout={300} classNames={{
                    enter: dialogStyles['transition-enter'],
                    enterActive: dialogStyles['transition-enter-active'],
                    exit: dialogStyles['transition-exit'],
                    exitActive: dialogStyles['transition-exit-active']
                }}>
                    {dialog}
                </CSSTransition> : null}
            </TransitionGroup>
        </div>
    )

}

export default Dashboard;