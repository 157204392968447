export const formatDate = (date) => {
      
    const dayOfMonth = date.getDate();
    const dayOfMonthSuffix = dayOfMonth > 3 && dayOfMonth < 21 ? 'th' : ['st', 'nd', 'rd'][dayOfMonth % 10 - 1] || 'th';
    
    return `${dayOfMonth}${dayOfMonthSuffix} of ${date.toLocaleString('en-US', { month: 'long' })}, ${date.getFullYear()}`;
      
}

export const validateURL = (input) => /^(https?|http):\/\/(localhost(:\d+)?|(?:[^\s/$.?#].[^\s]*)\.[a-zA-Z]{2,})(?:\/[^\s]*)?$/gmi.test(input);

