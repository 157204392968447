import { useEffect, useRef, useState } from 'react';
import styles from '../../assets/css/dialog.module.css';
import mStyles from '../../assets/css/dialogs/member.module.css';
import { useStore } from '../../assets/js/Store';
import { DangerButton, PrimaryButton, SecondaryButton } from '../../components/Buttons';
import { addMember, removeMember, useCurrentProject } from '../../assets/js/Api';
import Input from '../../components/Input';

export const AddMember = ({ close, onInvite }) => {

    const setDialog = useStore((state) => state.setDialog);
    const setCloseDialog = useStore((state) => state.setCloseDialog);
    const closeFunction = useStore((state) => state.closeDialog);

    const [loading, setLoading] = useState(false);
    const [identifier, setIdentifier] = useState('');
    const [error, setError] = useState(null);

    useEffect(() => {

        if (closeFunction !== undefined) setCloseDialog(closeFunction);

    }, [setCloseDialog, close, closeFunction]);

    return ( 
        <div className={[styles.dialog].join(' ').trim()}>
            <h2>{`Add Member`}</h2>
            <Input error={error} onFocus={() => setError(null)} className={mStyles.input} placeholder={'E-Mail or Username'} onChange={v => setIdentifier(v)} onSubmit={e => {
            
                setLoading(true);
                (onInvite ? onInvite : () => {})(identifier, setLoading, setError);
            }}/>
            <div className={mStyles['button-tray']}>
                <SecondaryButton className={mStyles.button} label={'Cancel'} onClick={close ? close : () => setDialog(null)} disabled={loading}/>
                <PrimaryButton className={mStyles.button} label={'Invite'} isLoading={loading} onClick={() => {
                    setLoading(true);
                    (onInvite ? onInvite : () => {})(identifier, setLoading, setError);
                }}/>
            </div>
        </div>
    )

}

export default AddMember;