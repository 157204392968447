import { useEffect, useState } from 'react';
import styles from '../assets/css/buttons.module.css';
import Loader from './Loader';

export const PrimaryButton = ({ isLoading, disabled, label, onClick, className, contentClassName, style }) => {

    const [loading, setLoading] = useState(isLoading);
    useEffect(() => setLoading(isLoading), [isLoading]);

    return <button style={style} type={'button'} disabled={disabled} className={[className, styles.button, loading ? styles.loading : null, disabled ? styles.disabled : null].join(' ').trim()} onClick={(event) => {
        if (isLoading) return;
        (onClick ? onClick : () => {})({
            initialEvent : event,
            setLoading   : setLoading
        })
    }}>
        <div className={[styles.contents, contentClassName].join(' ').trim()}>
            {loading ? <Loader className={styles.loading}/> : null}
            <div className={styles.label}>{label}</div>
        </div>
    </button>

}

export const SecondaryButton = ({ isLoading, disabled, label, onClick, className, contentClassName}) => <PrimaryButton isLoading={isLoading} disabled={disabled} label={label} onClick={onClick} contentClassName={contentClassName} className={[styles.secondary, className].join(' ').trim()}/>
export const DangerButton = ({ isLoading, disabled,label, onClick, className, contentClassName}) => <PrimaryButton isLoading={isLoading} disabled={disabled} label={label} onClick={onClick} contentClassName={contentClassName} className={[styles.danger, className, contentClassName].join(' ').trim()}/>
