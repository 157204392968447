import { useEffect, useRef, useState } from 'react';
import styles from '../assets/css/slider.module.css';

export const Slider = ({ value, className, onChange }) => {

    const [_value, setValue] = useState(value);
    const [dragging, setDragging] = useState(false);
    const trackRef = useRef(null);
    const grabberRef = useRef(null);

    useEffect(() => {

        if (!trackRef.current) return;

        const onMouseMove = (event) => {
            if (!dragging) return;
            const bcr = trackRef.current.getBoundingClientRect();
            const percent = Math.round(Math.min((100 / bcr.width) * Math.max(event.clientX - bcr.left, 0), 100) * 100) / 100;
            setValue(percent);
            (onChange ? onChange : () => {})(percent);
        }
        const onMouseUp = (event) => {
            if (!dragging) return;
            setDragging(false);
            const bcr = trackRef.current.getBoundingClientRect();
            const percent = Math.round(Math.min((100 / bcr.width) * Math.max(event.clientX - bcr.left, 0), 100) * 100) / 100;
            setValue(percent);
            (onChange ? onChange : () => {})(percent);
        }

        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);

        return () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        }

    }, [dragging, onChange]);

    return (
        <div className={[styles.slider, className].join(' ').trim()}>
            <div className={styles.track} ref={trackRef} style={{...(_value !== undefined ? {'--value': `${_value}%`} : {})}}>
                <div className={[styles.grabber, styles.dragging].join(' ').trim()} ref={grabberRef} style={{left: `${_value}% `}} onMouseDown={() => setDragging(true)}/>
            </div>
        </div>
    )

}

export default Slider;